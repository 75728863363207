







import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import romaniaLow from '@amcharts/amcharts4-geodata/romaniaLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { MapNumber } from '../../../models/Map';

am4core.useTheme(am4themes_animated);

@Component
export default class MapContainer extends Vue {
  public $refs!: Vue['$refs'] & {
    map: any;
  };

  @Prop({ required: true }) public mapNumbers: MapNumber[];
  @Prop() public expandedCounty: string;

  public map: am4maps.MapChart;
  @Emit('onMapClick') public onMapClick(county: string) {
    return county;
  }

  @Watch('mapNumbers', { immediate: false }) public onMapNumbersUpdate() {
    this.initMap();
  }
  @Watch('expandedCounty') public onExpandedCountyChange(county: string) {
    if (county) {
      const selectedPolygon = (this.map.series.getIndex(
        0,
      ) as am4maps.MapPolygonSeries).getPolygonById(`RO-${county}`);
      if (selectedPolygon) {
        selectedPolygon.isActive = true;
      }
    } else {
      (this.map.series.getIndex(
        0,
      ) as am4maps.MapPolygonSeries).mapPolygons.each(
        (mapPolygon: am4maps.MapPolygon) => {
          mapPolygon.isActive = false;
        },
      );
    }
  }

  public mounted() {
    this.initMap();
  }

  public beforeDestroy() {
    if (this.map) {
      this.map.dispose();
    }
  }

  private initMap() {
    if (!this.mapNumbers) {
      return;
    }
    const map = am4core.create(this.$refs.map, am4maps.MapChart);
    map.geodata = romaniaLow;
    map.projection = new am4maps.projections.Miller();
    map.seriesContainer.draggable = false;
    map.seriesContainer.resizable = false;
    map.seriesContainer.events.disableType('doublehit');
    map.chartContainer.background.events.disableType('doublehit');
    map.chartContainer.wheelable = false;
    const polygonSeries = map.series.push(new am4maps.MapPolygonSeries());

    polygonSeries.data = this.mapNumbers;
    polygonSeries.tooltip.getFillFromObject = false;
    polygonSeries.tooltip.background.fill = am4core.color('#F03511');

    polygonSeries.useGeodata = true;

    const labelSeries = map.series.push(new am4maps.MapImageSeries());
    const labelTemplate = labelSeries.mapImages.template.createChild(
      am4core.Label,
    );
    labelTemplate.horizontalCenter = 'middle';
    labelTemplate.verticalCenter = 'middle';
    labelTemplate.fontWeight = '700';
    labelTemplate.nonScaling = true;
    labelTemplate.fontSize = '1em';
    labelTemplate.interactionsEnabled = false;
    labelTemplate.zIndex = 999;
    labelTemplate.dy = -12;

    const circleTemplate = labelSeries.mapImages.template.createChild(
      am4core.Circle,
    );
    circleTemplate.horizontalCenter = 'middle';
    circleTemplate.verticalCenter = 'middle';
    circleTemplate.radius = 4;
    circleTemplate.fill = am4core.color('#F03511');
    circleTemplate.nonScaling = true;
    circleTemplate.zIndex = 998;
    circleTemplate.interactionsEnabled = false;

    polygonSeries.events.on('inited', () => {
      polygonSeries.mapPolygons.each((polygon: am4maps.MapPolygon) => {
        const dataContext = polygon.dataItem.dataContext as {
          value: string;
          id: string;
        };
        const label = labelSeries.mapImages.create();
        const count = dataContext.value;
        label.latitude = polygon.visualLatitude;
        label.longitude = polygon.visualLongitude;
        if (dataContext.id.split('-')[1] === 'IF') {
          label.dy = -13;
        }
        (label.children.getIndex(0) as am4core.Label).text = count;
      });
    });

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipHTML = `
      <div style="font-size: 1.2em" class="d-flex flex-column justify-content-center align-items-center">
      <div><strong>{name}</strong></div>
      <div>{value} coruri</div>
      </div>
      `;
    polygonTemplate.fill = am4core.color('#ffffff');
    polygonTemplate.stroke = am4core.color('#E7E7ED');
    polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    polygonTemplate.events.on('hit', (event) => {
      const dataContext = event.target.dataItem.dataContext as { id: string };
      this.onMapClick(dataContext.id.split('-')[1]);
    });

    const hoverState = polygonTemplate.states.create('hover');
    hoverState.properties.fill = am4core.color('#F4654A');
    const activeState = polygonTemplate.states.create('active');
    activeState.properties.fill = am4core.color('#F4654A');

    this.map = map;
  }
}
